import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'checkout/:purchaseTokenKey',
    loadComponent: () =>
      import('./checkout/checkout.component')
          .then(m => m.CheckoutComponent),
  },
  {
    path: ':lang/checkout/:purchaseTokenKey',
    loadComponent: () =>
      import('./checkout/checkout.component')
          .then(m => m.CheckoutComponent),
  },
  {
    path: 'success-payment/:paymentMethodType/:orderTempUid',
    loadComponent: () =>
      import('./success-payment/success-payment.component')
          .then(m => m.SuccessPaymentComponent),
  },
  {
    path: 'error-payment/:orderTempUid',
    loadComponent: () =>
      import('./error-payment/error-payment.component')
          .then(m => m.ErrorPaymentComponent),
  },
  {
    path: 'pending-payment/:orderTempUid',
    loadComponent: () =>
      import('./pending-payment/pending-payment.component')
          .then(m => m.PendingPaymentComponent),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./error404/error404.component')
        .then(m => m.Error404Component),
  },
];
